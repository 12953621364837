import React, { useRef, useState, Suspense } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Points, PointMaterial, Preload } from "@react-three/drei";
import * as random from "maath/random/dist/maath-random.esm";
import styled from "styled-components";

// Styled component to wrap the Canvas element, making it fullscreen and positioned absolutely
const StyledCanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  background: linear-gradient(
    38.73deg,
    rgba(204, 0, 187, 0.15) 0%,
    rgba(201, 32, 184, 0) 50%
  ),
  linear-gradient(
    141.27deg,
    rgba(0, 70, 209, 0) 50%,
    rgba(0, 70, 209, 0.15) 100%
  );
`;

// Component that creates and animates a group of stars
const StarGroup = ({ color, speed }) => {
  const ref = useRef();
  const [sphere] = useState(() => random.inSphere(new Float32Array(1700), { radius: 1.2 }));

  useFrame((state, delta) => {
    ref.current.rotation.x -= delta * speed;
    ref.current.rotation.y -= delta * speed;
  });

  return (
    <Points ref={ref} positions={sphere} stride={3} frustumCulled>
      <PointMaterial
        transparent
        color={color}
        size={0.003}
        sizeAttenuation={true}
        depthWrite={false}
      />
    </Points>
  );
};

// Main component wrapping the Canvas with the styled wrapper
const StyledStarsCanvas = () => {
  return (
    <StyledCanvasWrapper>
      <Canvas camera={{ position: [0, 0, 1] }}>
        <Suspense fallback={null}>
          {/* Group of stars with different colors and speeds */}
          <StarGroup color="#FF1493" speed={0.05} />
          <StarGroup color="#00BFFF" speed={0.05} />
          <StarGroup color="#FF4500" speed={0.05} />
        </Suspense>
        <Preload all />
      </Canvas>
    </StyledCanvasWrapper>
  );
};

export default StyledStarsCanvas;
