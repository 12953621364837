export const Bio = {
  name: "Jack McGrory",
  roles: [
    "an Aspiring Developer",
    "a Straight A Student",
    "interested in Front End",
    "interested in Back End",
    "interested in Full Stack",
    "interested in AI/ML",
    "interested in ..."
  ],
  description:
    "I hold a Master of Engineering degree with Distinction in Computer and Electronic Systems. Over my 5 years, I obtained A’s in every single university module. Additionally, I have undertook 3 internships and completed numerous projects in areas from app development to AI and machine learning.",
  linkedin: "https://www.linkedin.com/in/jack-mcgrory-536b37165/",
};

export const skills = [
  {
    title: "Web Development",
    skills: [
      {
        name: "ReactJS",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "JavaScript and TypeScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/2048px-Typescript_logo_2020.svg.png",
      },
      {
        name: "CSS and SCSS",
        image:
          "https://sass-lang.com/assets/img/logos/logo.svg",
      },
      {
        name: "HTML5",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "NextJS",
        image:
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACTklEQVR4Ab1XAaQqURB9DyohSykREpRIQSAlBCoECKUFCSRCBBEAaSEABQEoCIEASCwAUICALgCo83do0//9v819XX845O7VnDkzOzP7JWGaBd3C3IJpQVjAHeJ+Rs9a97vKLGrBsB1KgMhEP3FMUUwt4ENMfxr1yQIU4SSjRkbeOZtERmHk6pXQVDlnkHh9S+QLTm1hkiz4n/gzFQuny9FoFLquE+i34x+n02k0m00UCoV3BIzn3MMJrVYLtp1OJ0cS/X4f5/MZhmG8IyDsWtDfEaDIn2232/3zbrvdxuFwwGg04qRBt+VnETBNE0IIkE2n07/erdfrWK/X6Ha73Hb9ZXII3G43ivy3dNRqtZe7lUoFs9mM6oBDwCQCgquALT1FT3a5XF7qIZ/PYzgcolqtcggIIgBZAgRKB6lCRalp2uM8k8mAVMrlchwC+DEBipycE4n5fP44j8ViKJVKSCaTbAJCpgaez4vFIsjoWa/XA50FAgEkEgmEw2F2CkxZBZ5Br5tt1ITcbjd8Ph88Hg+7CBefECCsVitS4aVJcV9D/VMCVITk/Hq9YrPZyBBo2a1YMGvAcQYcj0cCtWMugcdYNhjDiBrP25mx3++x3W6RzWZZ8isfxzQLlsslJpMJpYY5jhkqcOH1ejEYDDAej9FoNOByuZxGsfqVzC7KTqcDSkkqleKsZOqX0mAwiHK5DGrJfr+fs5SqX8sjkQji8ThCoRC+v78Za7l6JagrUh3YkUuZpqgwDaecc9VYSDoV5Fg+at7n+eLN57kuE/EvzHr/Kvs31aYAAAAASUVORK5CYII=",
      },
      {
        name: "Cypress Testing",
        image:
          "https://www.cypress.io/_astro/navbar-brand.D87396b0.svg",
      },



    
    ],
  },
  {
    title: "Artificial Intellegence",
    skills: [
        {
          name: "Python",
          image:
            "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
        },
        {
          name: "TensorFlow",
          image:
            "https://static-00.iconduck.com/assets.00/tensorflow-icon-1911x2048-1m2s54vn.png",
        },
        {
          name: "Keras",
          image:
            "https://miro.medium.com/v2/resize:fit:600/1*DKu_54iqz6C-p6ndo7rO3g.png",
        },
        {
          name: "Jupyter",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Jupyter_logo.svg/1767px-Jupyter_logo.svg.png",
        },
        {
          name: "Google Colab",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Google_Colaboratory_SVG_Logo.svg/1280px-Google_Colaboratory_SVG_Logo.svg.png",
        },
        {
          name: "Scikit-Learn",
          image:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Scikit_learn_logo_small.svg/2560px-Scikit_learn_logo_small.svg.png",
        }
    ],
  },
  {
    title: "Programming Languages",
    skills: [
      {
        name: "Java",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
      },
      {
        name: "Kotlin",
        image:
          "https://www.vectorlogo.zone/logos/kotlinlang/kotlinlang-icon.svg",
      },
      {
        name: "PHP",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/PHP-logo.svg/2560px-PHP-logo.svg.png",
      },
      {
        name: "C/C++",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1200px-ISO_C%2B%2B_Logo.svg.png",
      },
      {
        name: "C#/.NET",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/7/7d/Microsoft_.NET_logo.svg",
      },
      {
        name: "MatLab",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Matlab_Logo.png/1144px-Matlab_Logo.png",
      },
      {
        name: "SQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      }
    ],
  },
  {
    title: "Tools and Frameworks",
    skills: [
       
      {
        name: "VCS (Git)",
        image:
          "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png",
      }, 
      {
        name: "AWS Amplify",
        image:
          "https://seeklogo.com/images/A/aws-amplify-logo-D68DDB5AB1-seeklogo.com.png",
      }, 
      {
        name: "Postman",
        image:
          "https://cdn.worldvectorlogo.com/logos/postman.svg",
      }, 
      {
        name: "JIRA",
        image:
          "https://cdn.icon-icons.com/icons2/2699/PNG/512/atlassian_jira_logo_icon_170511.png",
      }, 
      {
        name: "Figma",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Figma-logo.svg/1667px-Figma-logo.svg.png",
      },
      {
        name: "Jetpack Compose",
        image:
          "https://3.bp.blogspot.com/-VVp3WvJvl84/X0Vu6EjYqDI/AAAAAAAAPjU/ZOMKiUlgfg8ok8DY8Hc-ocOvGdB0z86AgCLcBGAsYHQ/s1600/jetpack%2Bcompose%2Bicon_RGB.png",
      },
      {
        name: "XML",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBMw6_RdwKQ9bDFfnKDX1iwMl4bVJEvd9PP53XuIw&s",
      },
    ],
  }
];

export const experiences = [
  {
    id: -1,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4wdaEAWWLLLL6Je0QXqxDdGzO_dgRzrCdfA&s",
    role: "Software Engineer - API",
    company: "Lloyds Banking Group",
    date: "October 2024 - Current",
    desc: ["Exciting new role developing gamified experiences for end users",
      "Will be exposed to both iOS and Android",
      "More updates to come in due course"
    ],
  },
  {
    id: 0,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWSXqqaQFMUu7-PHXnYGaM5EiODKtNoteCWQ&s",
    role: "Computer and Electronic Systems (MEng) with Distinction",
    company: "University of Strathclyde",
    date: "June 2024",
    desc: ["Graduated with a 1st with a CWA of 84.6%", 
    "Achieved A's (>70%) in all modules over my 5 years",
     "Won two awards, one for best student on the course and one for best group project in the department"],
  },
  {
    id: 1,
    img: "https://s3-eu-west-1.amazonaws.com/hackajob-uploads1.p.hackajob/branding/logo/QBO43NO9QZCV5fJwEHAj_1643364871897.jfif",
    role: "Take Home Pay Calculator Team - Intern",
    company: "xDesign",
    date: "June 2023 - September 2023",
    desc: ["Developed a Next.js application for all xDesign employees to help them calculate and visualise the amount of money their take home salary.", 
    "Presented to over 300 people in a hugely successfully launch at the company update.",
     "Learned TypeScript, CSS and C# for the first time and helped deliver a batch testing tool that verified the application via API’s"],
    skills: [
      "ReactJS",
      "SCSS",
      "NodeJs",
      "C#",
      "AWS Amplify",
      "BitBucket",
    ],
  },
  {
    id: 2,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWSXqqaQFMUu7-PHXnYGaM5EiODKtNoteCWQ&s",
    role: "4th Year Dean's List",
    company: "University of Strathclyde",
    date: "June 2023",
    desc: ["Placed on the Dean's List for my high marks in 4th year", 
    "Completed my undergraduate dissertation on the development of an AI to detect Autism in young children via iPad games",
     "Was able to detect 90% of particpants who had Autism"],
    skills: [
      "AI Development",
      "TensorFlow",
      "Jupyter Notebook",
      "AI Evaluation Metrics",
      "xGBoost Models",
      "Feature Creation",
    ],
  },
  {
    id: 3,
    img: "https://asset.brandfetch.io/id3T02jwZd/idoURfZOJD.png",
    role: "Security and Connectivity Intern",
    company: "NXP Semiconductors",
    date: "June 2022 - September 2022",
    desc: ["Developed Java GUI application to detect configuration of an NXP chip and only apply suitable tests to the chip", 
    "Reworked framework of test suites so they can be included or excluded depending on the features used in each test case",
     "My mentor was extremely happy with the application and suggested that it could be used across the company to verify other products outside of the ones developed by my team"],
    skills: [
      "Java",
      "Java GUI's",
      "Inhouse Languages",
    ],
  },
  {
    id: 4,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWSXqqaQFMUu7-PHXnYGaM5EiODKtNoteCWQ&s",
    role: "3rd Year Dean's List",
    company: "University of Strathclyde",
    date: "June 2022",
    desc: ["Placed on the Dean's List for my high marks in 3rd year", 
    "Worked in group project to create a barcode scanning pen and website from scratch",
     "Also developed a Boston Metro System Java application via Swing to give users travel directions"],
    skills: [
      "Java",
      "Swing",
      "A* Search",
      "Graphs",
      "XML"
    ],
  },
  {
    id: 5,
    img: "https://asset.brandfetch.io/id3T02jwZd/idoURfZOJD.png",
    role: "Secure Transactions and ID Intern",
    company: "NXP Semiconductors",
    date: "June 2021 - September 2021",
    desc: ["Helped automate the testing of contactless cards", 
    "Developed automated tool, utilising Windows Batch File code, to deliver weekly metrics on the amount of the products source code that was actually covered by the developed test suites",
     "The metrics were passed up to senior management who were delighted with my work and asked me to return next year"],
    skills: [
      "Java Card",
      "Windows Batch Code",
      "Macros"
    ],
  },
  {
    id: 6,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWSXqqaQFMUu7-PHXnYGaM5EiODKtNoteCWQ&s",
    role: "1st and 2nd Year Dean's List",
    company: "University of Strathclyde",
    date: "June 2020 & June 2021",
    desc: ["Placed on the Dean's List for my high marks in both 1st and 2nd year", 
    "Achieved A's in all modules despite rapid change to online teaching halfway through 1st year"],
  },
  {
    id: 7,
    img: "https://upload.wikimedia.org/wikipedia/en/3/35/St_Peter_The_Apostle_High_School_crest.png",
    role: "Accepted to University",
    company: "St Peter the Apostle High School",
    date: "June 2019",
    desc: ["Recieved 5 unconditional offers to different universities", 
    "Achieved 15 A's across 3 Advanced Highers, 6 Highers and 6 National 5's",
     "Awarded the Lord McFall Outstanding Academic Acheivement Award for the highest marks in my year "],
  },

  
];


export const projects = [
  {
    id: 0,
    title: "Robotic Greenkeeper",
    date: "Jan 2024 - Jun 2024",
    description:["I am delighted to finished my MEng Group Project where I worked in a team to help create a fully automated robotic greenkeeper for the maintenance of golf courses.","Over the past year Amir Hussain, Blair Kennedy, Jake Malcolm, Job Derksen and I conceptualised and developed a robot that can autonomously navigate around a course and automatically detect and repair pitchmarks on the green.","I helped develop the hosted NextJS application that shows the robots live location and allows greenkeepers to start scans and map new greens on their course. The application also provides a detailed nutrient breakdown of the soil and it has the ability to provide tailored treatment advice via a custom GPT assistant.","I also helped develop the software architecture for the microcontrollers to allow them to communicate with the web application as well as a computer vision model to detect and navigate to 85% of pitchmarks on the surface of the green.","We recently demonstrated the robot to a collection of industry experts at a tradeshow and it was exceptionally rewarding to receive such positive feedback to conclude my 5 years at the University of Strathclyde!"],
       image:
      "https://github.com/jackmcgrory/cvwebsiteresources/assets/51889186/1a56a933-433a-44d8-abb6-86b5a6ad8084",
      yvideo: "https://www.youtube.com/embed/abdqs39ClUo"
  },
  {
    id: 1,
    title: "CV Website",
    date: "Jun 2024",
    description:
      ["After finishing up with university, I began to develop this very website to act as a portfolio/CV for potential employers", "I developed the site using React and numerous libraries that were new to me such as ThreeJS, Styled-Components and more"],
    image:
      "https://github.com/jackmcgrory/cvwebsiteresources/assets/51889186/01e14894-eba0-47b6-b18e-8ca2229abf27",
  },
  {
    id: 2,
    title: "AI to Detect Autism",
    date: "Sept 2022 - May 2023",
    description:
      ["Delighted to complete my honours year project where I developed an AI model to detect autism in children based off their gameplay from an iPad game along with the results of some questions from their parents.", "The developed model was able to correctly identify 90% of participants with ASD (Autism Spectrum Disorder) which was a great achievement.","I also presented my results at a small seminar for ASD experts. I am very happy to have undertook such an exciting and impactful project at the University of Strathclyde "],
    image:
      "https://github.com/jackmcgrory/cvwebsiteresources/assets/51889186/6757b3f5-b59a-4611-b921-d2fb7831dfda",
  },
  {
    id: 4,
    title: "MyMedication App",
    date: "Sept 2023 - Dec 2023",
    description:
      ["For one of my 5th year classes I had to design an application for vulnerable people by following the User Centered Design process (UCD)", "Utilising Figma, I developed a medication aid that paired with pill boxes to guide people through the process of medication management", "This was my highest mark in 5th year, where I got 98% for the module"],
    image:
      "https://github.com/jackmcgrory/cvwebsiteresources/assets/51889186/651ebd05-e3d2-431e-ad5c-a5b3c6f470a6",

  },
  {
    id: 5,
    title: "Live Train Sign",
    date: "July 2023",
    description:
      ["In this small project I undertook in my own time, I used a Raspberry Pi Zero W and a small LCD display I had to display the live train times of my local station.","I utilised the National Rail Enquiries API to request information about train times as well as a handy application to format the data (as you would see at any train station) which I found on BalenaCloud"],
    image:
      "https://github.com/jackmcgrory/cvwebsiteresources/assets/51889186/e7aead65-8d32-41ae-b912-1b9557278d48",
      video: "https://github.com/jackmcgrory/cvwebsiteresources/assets/51889186/6cbd4497-7112-436c-bf91-52629f8b73b6"
  },
  {
    id: 6,
    title: "Supermarket Barcode Pen",
    date: "Jan 2022 - May 2022",
    description:
      ["In this 3rd year project, I developed a Bluetooth barcode scanning pen from a selection of components and software. The project started with an MSP-430 microcontroller to which we added serial Bluetooth communication with a HC-05 chip and also developed a Java application that could; Initiate a connection with the device; Read in the data into a string; close the connection.","Once this was enabled we then moved onto connecting a photodiode to the board and processing it via a Schmitt trigger circuit to remove any background noise in the signal. Then via ADC (analog to digital conversion) this signal was converted and sent to the Bluetooth chip.","Finally on the software side I developed an algorithm which would decode the binary string into the scanned EAN-13 barcode number. This involved pattern matching to estimate the size of one strip as well as determining what character was represented by the stripe sequence. Once the item number was obtained our code then added the item scanned to the users basket which appeared on a website for the user to monitor the total cost as well as delete and update their basket accordingly.","Finally to tidy things up we designed and manufactured our own PCB to implement the circuit components we had designed in a compact manner on top of the MSP-430."],
    image:
      "https://github.com/jackmcgrory/cvwebsiteresources/assets/51889186/4b79c6c0-9c78-433d-8fba-1406831a618c",
  },
  {
    id: 7,
    title: "Boston Metro System",
    date: "Sept 2021 - Dec 2021",
    description:
      ["In this 3rd year project, I developed a handy travel tool for users of the Boston Metro System using Java, Swing and XML.", "One of the key goals was to be completely abstract so that the designed system would work for any metro system and would only read in information about the metro system from a text file.", "The GUI application would then display a fully coloured map which users could click on to select stations (they could also type stations in or swap them round) and they would receive instructions on the shortest possible route between the two stations.","The algorithm used to find the shortest route was the A* Algorithm which would consider the time between stations rather than just the shortest route. However, it also considered that if you had to change line this would cost time too and this was factored in to delivering the shortest time."],
    image:
      "https://github.com/jackmcgrory/cvwebsiteresources/assets/51889186/8c0241ca-4163-4f19-8d2b-d06a7f15f26e",
      video: "https://github.com/jackmcgrory/cvwebsiteresources/assets/51889186/ca416d22-1b6b-4ce3-990a-e41856eb1723",
  },
  {
    id: 8,
    title: "Task Manager",
    date: "Sept 2021",
    description:
      ["In this shorter project I implemented a Task Manager GUI within the Java Virtual Machine.", "Here I learned the importance of concurrency and multithreading and implemented several features to filter through the types of threads, search for thread by name as well as the ability to add and kill named threads."],
    image:
      "https://github.com/jackmcgrory/cvwebsiteresources/assets/51889186/452cc5a7-2bae-429d-aecf-1fe7909e89e8",
  },
];

