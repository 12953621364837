import styled, { ThemeProvider } from 'styled-components'
import {darkTheme} from './utils/Themes'
import NavigationBar from './components/NavigationBar';
import { BrowserRouter } from 'react-router-dom';
import AboutMe from './components/AboutMe';
import Skills from './components/skills';
import Experience from './components/experience';
import StarCanvas from "./components/canvas/Stars";
import Projects from './components/projects';
import Footer from './components/footer';
import { Analytics } from "@vercel/analytics/react"
import React from "react";


const Body = styled.div`
  background-color: ${({theme}) => theme.bg};
  color: ${({theme}) => theme.text_primary};
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
`


function App() {


  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
      <NavigationBar/>
      <Body>
      <StarCanvas/>
        <AboutMe/>
        <Experience/>
        <Skills/>
        <Projects/>
        <Footer/> 
        <Analytics/>
      </Body>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
