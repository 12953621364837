export const darkTheme = {
  bg: "#090917",
  bgLight: "#1C1E27",
  primary: "#2ECC71", 
  text_primary: "#F2F3F4",
  text_secondary: "#b1b2b3",
  card: "#171721",
  card_light: "#191924",
  educationCard: "rgba(17, 25, 40, 0.83)", 
  button: "#2ECC71", 
  white: "#FFFFFF",
  black: "#000000",
};
