import React, { useState } from 'react'
import {Link as LinkR} from 'react-router-dom'
import {MenuRounded} from '@mui/icons-material'
import styled, { useTheme } from 'styled-components'


const Navigation = styled.div`
background-color: ${({ theme }) => theme.bg};
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
`;

const NavigationContainer = styled.div`
width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
`;
const NavigationTitle = styled(LinkR)`
width: 80%;
padding: 0 6px;
font-weight: 500;
font-size: 18px;
text-decoration: none;
color: inherit;
    white-space: nowrap;
`;

const NavigationItems = styled.ul`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
gap: 32px;
padding: 0 6px;
list-style: none;

@media screen and (max-width: 768px) {
  display: none;
}

`

const NavigationLink = styled.a`
color: ${({ theme }) => theme.text_primary};
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`

const CVButtonContainer = styled.div`
width: 80%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 6px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const CVButton = styled.a`
border: 1px solid  ${({theme}) => theme.primary};
justify-content : center;
display: flex;
cursor: pointer;
border-radius: 20px;
padding: 8px 20px;
font-size : 16px;
color:  ${({theme}) => theme.primary};
font-weight : 500;
transition: all 0.6s ease-in-out;
text-decoration: none;
&:hover{
background: ${({theme}) => theme.primary};
color: ${({theme}) => theme.text_primary};
}
`;

const MobileMenu = styled.div`
height: 100%;
display: flex;
align-items: center;
color: ${({ theme }) => theme.text_primary};
display: none;
@media screen and (max-width: 768px) {
  display: block;
}
`;

const MobileList = styled.ul`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
gap: 16px;
padding: 12px 40px 24px 40px;
background: ${({theme}) => theme.card_light +99};
position: absolute;
top: 80px;
right :0;
list-style: none;
transition: all 1s ease-in-out;
transform: ${({ menuOpen }) =>
    menuOpen ? "translateY(0)" : "translateY(-100%)"};
box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
opacity: ${({ menuOpen }) => (menuOpen ? "100%" : "0")};
z-index: ${({ menuOpen }) => (menuOpen ? "1000" : "-1000")};
`



const NavigationBar = () => {

    const [menuOpen, setMenuOpen] = useState(false);
    const theme = useTheme();

    const downloadCV = () => {
      const pdfURL = "NewGradBase.pdf";
      const link = document.createElement("a");
      link.href = pdfURL;
      link.download = "JackMcGroryCV.pdf"
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  return (
    <Navigation>
    <NavigationContainer>
      <NavigationTitle to="/">
           Jack McGrory
        </NavigationTitle>
        <MobileMenu onClick={() => setMenuOpen(!menuOpen)}>
            <MenuRounded style={{color:'inherit'}}/>
        </MobileMenu>
        <NavigationItems>
            <NavigationLink href="#About">About</NavigationLink>
            <NavigationLink href="#Experience">Experience</NavigationLink>
            <NavigationLink href="#Languages">Languages</NavigationLink>
            <NavigationLink href="#Projects">Projects</NavigationLink>
            <NavigationLink href="mailto:jack.mcgrory@virginmedia.com" target="_blank" rel="noopener noreferrer">Contact</NavigationLink>
        </NavigationItems>
        {
            menuOpen &&
            // need to pass css var in
            <MobileList menuOpen={menuOpen}>
                <NavigationLink onClick={()=> setMenuOpen(false)} href="#About">About</NavigationLink>
                <NavigationLink onClick={()=> setMenuOpen(false)} href="#Experience">Experience</NavigationLink>
                <NavigationLink onClick={()=> setMenuOpen(false)} href="#Languages">Languages</NavigationLink>
                <NavigationLink  onClick={()=> setMenuOpen(false)} href="#Projects">Projects</NavigationLink>
                <NavigationLink onClick={()=> setMenuOpen(false)} href="mailto:jack.mcgrory@virginmedia.com" target="_blank" rel="noopener noreferrer">Contact</NavigationLink>
                <CVButton onClick={downloadCV} style={{
                    background: theme.primary,
                    color: theme.text_primary
                }}>Download CV</CVButton>
            </MobileList>
        }
        <CVButtonContainer onClick={downloadCV}>
            <CVButton>Download CV</CVButton>
        </CVButtonContainer>
    </NavigationContainer>
    </Navigation>
  )
}

export default NavigationBar