import React from "react";
import styled from "styled-components";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalCard = styled.div`
  width: 90%;
  max-width: 800px;
  background-color: ${({ theme }) => theme.card};
  border-radius: 10px;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.4);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 95%;
    padding: 15px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.text_secondary};
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 500rem;
  border: none;
  border-radius: 10px;

  @media (max-width: 768px) {
    height: 800rem;
  }
`;

const LargeProject = ({ project, onClose }) => {
  return (
    <ModalBackground onClick={onClose}>
      <ModalCard onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {project.video || project.yvideo ? (
          project.video ? (
            <video controls style={{ width: '100%', borderRadius: '10px' }}>
              <source src={project.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <StyledIframe
              src="https://www.youtube.com/embed/abdqs39ClUo"
              title="Group D Video Robotic Greenkeeper"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          )
        ) : (
          <img
            src={project.image}
            alt={project.title}
            style={{ width: '100%', borderRadius: '10px' }}
          />
        )}
        <div>
          <h2>{project.title}</h2>
          <p>{project.date}</p>
          <p>{project.description.map((line, index) => (
            <React.Fragment key={index}>{line}<br /><br /></React.Fragment>
          ))}</p>
        </div>
      </ModalCard>
    </ModalBackground>
  );
};

export default LargeProject;
