import React from 'react'
import styled from "styled-components"
import { Bio } from "./../data/constants";
import JackImage from './../images/jack.JPG';
import Typewriter from "typewriter-effect";
import AboutMeBackgroundAnimation from './AboutMeBackgroundAnimation';
import {Tilt} from "react-tilt";
// these make text look nice when they load in :0
import { motion } from "framer-motion";
import {
  headContainerAnimation,
  headContentAnimation,
  headTextAnimation,
} from "./../utils/motion";



const AboutMeContainer = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    padding: 80px 30px;
    z-index: 1;

    @media (max-width: 960px){
        padding: 66px 16px;
    }

    @media (max-width: 640px){
        padding: 32px 16px;
    }

    clip-path: polygon(0 0, 100% 0, 100% 100%, 70% 95%, 0 100%);
`;
const AboutMeInnerContainer = styled.div`
position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1100px;

  @media (max-width: 960px) {
    flex-direction: column;
  }`;

const AboutMeLeftContainer = styled.div`
width: 100%;
  order: 1;
  @media (max-width: 960px) {
    order: 2;
    margin-bottom: 30px;
    display: flex;
    gap: 6px;
    flex-direction: column;
    align-items: center;
  }`;
const AboutMeRightContainer = styled.div`
width: 100%;
order: 2;
display: flex;
justify-content: end;
@media (max-width: 960px) {
  order: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-contents: center;
  margin-bottom: 80px;
}

@media (max-width: 640px) {
  margin-bottom: 30px;
}`;

const Title = styled.div`
  font-weight: 700;
  font-size: 50px;
  color: ${({ theme }) => theme.text_primary};
  line-height: 68px;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 960px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 8px;
  }
`;

const TypeWriter = styled.div`
  font-weight: 600;
  font-size: 32px;
  display: flex;
  gap: 12px;
  color: ${({ theme }) => theme.text_primary};
  line-height: 68px;

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 960px) {
    font-size: 22px;
    line-height: 48px;
    margin-bottom: 16px;
  }
`;

const Span = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.primary};
`;

const SubTitle = styled.div`
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 42px;
  color: ${({ theme }) => theme.text_primary + 95};

  @media (max-width: 960px) {
    text-align: center;
  }

  @media (max-width: 960px) {
    font-size: 16px;
    line-height: 32px;
  }
`;

const CircleImage = styled.img`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
  @media (max-width: 640px) {
    max-width: 280px;
    max-height: 280px;
  }
`;

const AboutMeBackground = styled.div`
  position: absolute;
  display: flex;
  justify-content: end;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 1360px;
  overflow: hidden;
  padding: 0 30px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  @media (max-width: 960px) {
    justify-content: center;
    padding: 0 0px;
  }
`;


const AboutMe = () => {
  return (
    <div id="About">
        <AboutMeContainer>
            <AboutMeBackground>
                <AboutMeBackgroundAnimation/>
            </AboutMeBackground>
            <motion.div {...headContainerAnimation}>
            <AboutMeInnerContainer>
                <AboutMeLeftContainer>
                <motion.div {...headTextAnimation}>
                <Title> Hi, I am <br/> {Bio.name}</Title>
                <TypeWriter>I am  
                    <Span>
                        <Typewriter options={{strings: Bio.roles,
                        autoStart: true,
                        loop: true,}}/>
                    </Span>
                </TypeWriter>
                </motion.div>
                <motion.div {...headContentAnimation}>
                <SubTitle>{Bio.description}</SubTitle>
                </motion.div>

                </AboutMeLeftContainer>
                <AboutMeRightContainer>
                <motion.div {...headContentAnimation}>
                    <Tilt>
                            <CircleImage src={JackImage} alt="Photo of Jack McGrory"></CircleImage>
                    </Tilt>
                </motion.div>
                </AboutMeRightContainer>
            </AboutMeInnerContainer>
            </motion.div>
        </AboutMeContainer>
    </div>
  )
}

export default AboutMe